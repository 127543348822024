import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaLink } from "react-icons/fa"

const isBrowser = typeof window !== "undefined"

const ShareSocial = () => {
  const [url, setUrl] = useState("")

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  const copyUrl = (e) => {
    e.preventDefault()

    var textField = document.createElement("textarea")

    textField.innerText = isBrowser ? window.location.href : ""
    document.body.appendChild(textField)
    textField.select()
    document.execCommand("copy")
    textField.remove()
  }

  return (
    <SocialWrapper>
      <p>Share this article</p>
      <IconWrapper className="d-flex align-items-end justify-content-center">
        <IconLink
          href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
            url
          )}`}
          target="_blank"
        >
          <FaFacebookF />
        </IconLink>
        <IconLink
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            url
          )}`}
          target="_blank"
        >
          <FaLinkedinIn />
        </IconLink>
        <IconLink
          href={`http://twitter.com/share?url=${encodeURIComponent(url)}`}
          target="_blank"
        >
          <FaTwitter />
        </IconLink>
        <IconLink onClick={copyUrl}>
          <FaLink />
        </IconLink>
      </IconWrapper>
    </SocialWrapper>
  )
}
const SocialWrapper = styled.div`
  margin-top: 48px;
  text-align: center;
  opacity: 0.6;
  color: ${({ theme }) => theme.colors.navyPrimary};

  p {
    font-family: helvetica;
    margin-bottom: 8px !important;
  }
`

const IconWrapper = styled.div`
  a {
    margin: 0 20px;
  }
  svg {
    fill: ${({ theme }) => theme.colors.navyPrimary};
    opacity: 0.6;
    width: 21px;
    height: 21px;
  }
`

const IconLink = styled.a`
  cursor: pointer;
`

export default ShareSocial
