import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { take } from "lodash";
import Layout from "../../components/layouts";
import { Container } from "../../components/layouts/components/Container";
import { Title, Blog as BlogItem } from "../../components/blog";
import FormPanelDesktopComponent from "../../components/layouts/FormPanelDesktopComponent";
import { RichText } from "prismic-reactjs";
import { format } from "date-fns";
import ShareSocial from "./ShareSocial";
import ContentRenderer, { Triangles, TriangleWrapper } from "../ContentRender";
import CallToAction from "./CallToAction";
import SEO from "../seo";
import { mapPrismicHeaderToTag } from "../../utils/headingMapper";
import { canonicalUrlResolver } from "../../utils/routeHelper"

export const query = graphql`
  query BlogPostQuery($uid: String!) {
    prismic {
      blog(lang: "en-gb", uid: $uid) {
        title
        subtitle
        published_date
        featured_image
        author_name
        read_time
        _meta {
          uid
        }
        large_call_to_action {
          call_to_action_image
          call_to_action_text
          call_to_action_title
          call_to_action_link {
            _linkType
            ... on PRISMIC__FileLink {
              _linkType
              url
            }
          }
        }
        seo {
          seo_description
          seo_image
          seo_title
          keywords
          business_information {
            ... on PRISMIC_Business_information {
              business_name
              logo
              address
              city
              country
              description
              state_region
              telephone
              url
              zip
              facebook_url
              linkedin_url
              email
            }
          }
        }
        content_group {
          content
          small_call_to_action {
            ... on PRISMIC_Call_to_action {
              title
              subtitle
              image
              link {
                link_text
                link_url {
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
              }
            }
          }
        }
        related_blogs {
          related_blog {
            ... on PRISMIC_Blog {
              title
              featured_image
              published_date
              subtitle
              _meta {
                tags
                uid
              }
            }
          }
        }
      }
    }
  }
`;

const Blog = ({ data, location }) => {
  const blog = data.prismic.blog;
  
  if (!blog) return null;
  const seo = data.prismic.blog?.seo;
  
  const blogTitleTag = mapPrismicHeaderToTag(blog.title[0].type);

  const url = canonicalUrlResolver(location?.pathname);

  return (
    <Layout canonicalUrl={url}>
      {seo && <SEO seo={seo} siteUrl={data.site?.siteMetadata?.siteUrl} />}
      <ContentContainer className="d-flex align-items-center flex-column">
        <BlogTitle as={blogTitleTag}>{RichText.asText(blog.title)}</BlogTitle>
        <div className="d-none d-md-block">
          <Triangles width="8px" height="8px" margin="12px" />
        </div>
        <SubSection>
          <p className="mb-2">Read Time: {RichText.asText(blog.read_time)}</p>
          <p className="m-0">
            {blog.author_name} | {format(new Date(blog?.published_date), "PPP")}
          </p>
        </SubSection>

        <FeaturedImageWrapper>
          <img
            src={blog.featured_image.url}
            alt="test"
            width="100%"
            height="100%"
          />
        </FeaturedImageWrapper>
        <BlogContentWrapper>
          <SubTitle>{RichText.asText(blog.subtitle)}</SubTitle>
          <ContentWrapper>
            <ContentRenderer content={blog.content_group} />
          </ContentWrapper>
        </BlogContentWrapper>
      </ContentContainer>
      {blog?.large_call_to_action.map((x) => (
        <CallToAction cta={x} />
      ))}
      <ContentContainer>
        <ShareSocial />
        {blog.related_blogs.filter((x) => x.related_blog).length > 0 ? (
          <>
            <DividerHR />
            <RelatedArticles>Related Articles</RelatedArticles>
            <div class="row pb-5">
              {take(blog.related_blogs, 2)?.map(({ related_blog }) => {
                const rblog = {
                  ...related_blog,
                  slug: related_blog?._meta?.uid,
                  tags: related_blog?._meta?.tags,
                };

                return (
                  <RelatedBlogWrapper className="col-md-6">
                    <BlogItem {...rblog} />
                  </RelatedBlogWrapper>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </ContentContainer>

      <FooterWrapper>
        <FormPanelDesktopComponent />
      </FooterWrapper>
    </Layout>
  );
};

const FooterWrapper = styled.div`
  padding-bottom: 48px;
`;
const ContentContainer = styled(Container)`
  margin-top: 48px;
  padding: 0 20px;

  @media (min-width: 992px) {
    padding: 0 120px 24px !important;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  @media (min-width: 992px) {
    padding: 0 0 48px;
  }
`;

const DividerHR = styled.hr`
  margin: 32px 0;
`;

const RelatedArticles = styled.h3`
  font-family: Agenda;
  font-size: 25px;
  font-weight: 600;
`;

const RelatedBlogWrapper = styled.div`
  margin-top: 32px;
`;

const headingFontSizes = {
  "h1": "39px",
  "h2": "35px",
  "h3": "32px",
  "h4": "24px",
  "h5": "20px",
  "h6": "16px",
};

const BlogTitle = styled(Title)`
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 32px;
  font-size: ${({ as }) => headingFontSizes[as] || headingFontSizes.h1};
`;

const SubSection = styled.div`
  color: ${({ theme }) => theme.colors.navyPrimary};
  opacity: 0.7;
  margin-bottom: 48px;

  p {
    text-transform: capitalize;
    text-align: center;
    font-family: Helvetica;
  }
`;

const FeaturedImageWrapper = styled.div`
  img {
    object-fit: cover;
    background-position: center;
    background-size: cover;
  }

  width: 100%;
  @media (min-width: 992px) {
    height: 500px;
  }

  margin-bottom: 48px;
`;

const BlogContentWrapper = styled.div`
  @media (min-width: 992px) {
    padding: 0 100px;
  }

  ${TriangleWrapper} {
    margin-bottom: 48px;
  }
`;

const ContentWrapper = styled.div`
  * {
    letter-spacing: 0;
    line-height: 28px;
    font-size: 1.25rem;
    font-family: Helvetica;
    opacity: 0.7;
    margin-bottom: 48px;
    color: ${({ theme }) => theme.colors.navyPrimary};
  }

  .no-opacity-content-wrapper {
    opacity: 1;

    * {
      opacity: 1;
    }
  }
`;

const SubTitle = styled.h2`
  font-size: 2rem !important;
  font-family: Agenda !important;
  margin-bottom: 48px;
  line-height: 37px;
  color: ${({ theme }) => theme.colors.navyPrimary};
`;

export default Blog;
